import React from "react";
import PropTypes from 'prop-types';
import { graphql } from "gatsby";
// ---------------------------------------------
import Header from "../components/header";
import Layout from "../components/layout";
import Markdown from "../components/markdown";
import Picture from "../components/picture";
import Transition from "../components/transition";
import Vimeo from "../components/vimeo";
import SEO from "../components/seo";

const getSafeColour = (colourSelection) => {
  if (colourSelection && colourSelection[0] && colourSelection[0].colour) {
    return colourSelection[0].colour;
  }

  return 'transparent';
}

const ArticlePage = ({ data, location }) => {

  const {
    article,
    articleHero,
    artist,
    colourSelection,
		title,
    headline,
    newsByline,
    newsCategory,
    newsThumbnail
  } = data.craft.entries[0];

  const colour = getSafeColour(colourSelection);

  const heroImage = (articleHero.length && articleHero) || (newsThumbnail.length && newsThumbnail);

  return (
    <Layout>
      <SEO background={colour} />
      <Transition
        location={location}
        render={handleLink => (
          <>
            <Header handleLink={handleLink} />
            <main className="head-offset">

              {/* Meta info below logo */}
              <div className="project-filter in-journal">
                {artist[0] ? (
                  <span className="heading--4">
                    {artist[0].title} - {newsCategory[0].title} &emsp;
                  </span>
                ) : (
                  <span className="heading--4">
                    {newsCategory[0].title} &emsp;
                  </span>
                )}
                {newsByline && (
                  <Markdown className="inline heading--4" convert={newsByline} />
                )}
              </div>

              {/* Hero content */}
              <div className={`article-hero ${headline ? 'span-2' : 'span-1'}`}>
								{headline && (
									<div className="col">
										<div className="headline">
											<h2>{headline}</h2>
										</div>
									</div>
								)}
                <div className="col">
                  <div className="cover">
                    {heroImage ? heroImage[0].vimeoUrl ? (
                      <Vimeo {...heroImage[0]} withControls />
                    ) : heroImage[0].source.length ? (
                      <Picture {...heroImage[0].source[0]} resize={{ width: 640 }} />
                    ) : null : null}
                  </div>
                </div>
              </div>

              {article && (
                <article
                  className="article-body"
                  dangerouslySetInnerHTML={{ __html: article }}
                />
              )}
            </main>
          </>
        )}
      />
    </Layout>
  )
}

ArticlePage.propTypes = {
  data: PropTypes.object.isRequired
}

export default ArticlePage

export const pageQuery = graphql`
  query ArticlePageQuery($slug: [String!]){
    craft {
      entries(type: "News", slug: $slug) {
        ... on Craft_news_news_Entry {
          ...NewsEntry
        }
      }
    }
  }
`

export const newsEntryFragment = graphql`
  fragment NewsEntry on Craft_news_news_Entry {
    id
    title
    slug
    colourSelection {
      ... on Craft_colours_Category {
        colour
      }
    }
    newsCategory {
      ... on Craft_newsTypes_Category {
        title
        slug
      }
    }
    projectLink {
      ... on Craft_projects_projects_Entry {
				id
        authorId
        slug
        byline
        client
        thumbnails {
          ... on Craft_thumbnails_images_BlockType {
            id
            typeHandle
            source {
              ... on Craft_minkAssets_Asset {
                ...Asset
              }
            }
          }
          ... on Craft_thumbnails_video_BlockType {
            id
            typeHandle
            vimeoUrl
						videoWidth
						videoHeight
						startTime
						endTime
          }
        }
      }
    }
    artist {
      ... on Craft_artists_artists_Entry {
        title
      }
    }
    newsThumbnail {
      ... on Craft_newsThumbnail_image_BlockType {
        id
        typeHandle
        source {
          ... on Craft_minkAssets_Asset {
            ...Asset
          }
        }
      }
      ... on Craft_newsThumbnail_video_BlockType {
        id
        typeHandle
        vimeoUrl
				videoWidth
				videoHeight
				startTime
				endTime
      }
    }
		newsItemSummary

    # -----------------
    # ARTICLE
    headline
    newsByline
    articleHero {
      ... on Craft_articleHero_image_BlockType {
        id
        typeHandle
        source {
          ... on Craft_minkAssets_Asset {
            ...Asset
          }
        }
      }
      ... on Craft_articleHero_video_BlockType {
        id
        typeHandle
        vimeoUrl
      }
    }
    article
    ## END
  }
`
