import React from "react";
import showdown from 'showdown';

const Markdown = ({convert, className}) => {
  const converter = new showdown.Converter({
    noHeaderId: true,
    simpleLineBreaks: true,
    openLinksInNewWindow: true
  });
  const copy = converter.makeHtml(convert);
  return <div className={`has-md ${className ? className : ""}`} dangerouslySetInnerHTML={{__html: copy}}></div>;
}

export default Markdown;
